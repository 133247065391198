import commonStyles from "../../styles/Common.module.css";
import { useEffect, useRef, useState } from "react";
import { Autocomplete, Button, TextField } from "@mui/material";
import { Add, ArrowUpward } from "@mui/icons-material";
import EditorComponent from "../Editor/EditorComponent";
import styles from "../PrimeResearch/styles/PrimeArticle.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getCompaniesFun } from "../../Redux/company/action";
import OverlayCircularProgress from "../Utils/OverlayCircularProgress";
import { initEditor } from "../../utils/initEditor";

const AddIpoArticle = () => {
    const dispatch = useDispatch();
    const ejInstanceRef = useRef();
    const { companies } = useSelector((store) => store.companies);
    const [ipoData, setIpoData] = useState({
        price: 0,
        company_Id: "",
        listing_date: "",
        opening_date: "",
        closing_date: ""
    });
    const [content, setContent] = useState([]);
    const [progress, setProgress] = useState(false);

    useEffect(() => {
        if (!companies.length) {
            dispatch(getCompaniesFun());
        };

        return () => {

        };
    }, []);

    useEffect(() => {

        // if (ejInstanceRef.current === null) {
            const DEFAULT_DATA = {
                "time": new Date().getTime(),
                "blocks": []
            }
            initEditor({ DEFAULT_INITIAL_DATA: DEFAULT_DATA, handleChange, ejInstanceRef });

            console.log({ editorJs: "initialized Init Editor" });
        // };

        console.log({ editorJs: "Invoked EditorJs component", ejInstanceRef })

        return () => {
            ejInstanceRef?.current?.destroy();
            ejInstanceRef.current = null;
        };
    }, []);

    const handleChange = async (data) => {

        setContent(data.blocks);
    };

    const handleDateChange = (dateType, value) => {
        setIpoData({
            ...ipoData,
            [dateType]: value
        });
    };

    const setCompanyId = (ele, value) => {
        setIpoData({
            ...ipoData,
            company_Id: value.id
        });
    };

    const handleSave = async () => {
        try {

            if (!content.length) {
                return alert("Add the IPO content, can't be empty");
            };

            if (!ipoData.company_Id) {
                return alert("Select the company");
            };

            if (!ipoData.listing_date || !ipoData.opening_date || !ipoData.closing_date) {
                return alert("Listing date, opening date and closing date requires");
            };

            setProgress(true);

            const res = await fetch(`https://api.sovrenn.com/ipo/new`, {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                    "Content-type": "application/json"
                },
                body: JSON.stringify({
                    ...ipoData,
                    content
                })
            });

            const data = await res.json();

            setProgress(false);

            if (res.ok) {
                alert("Published Article Successfully");
                return;
            };

            alert("Something went wrong");
            return;
        }
        catch (err) {

            console.log({ err });
            alert("Something went wrong");
            return;
        };
    };

    return (
        <div className={commonStyles.MainContainer}>
            <OverlayCircularProgress loading={progress} />

            <div className={commonStyles.headerDiv}>
                <h2>Add New IPO Article</h2>

                <div>
                    <Button onClick={handleSave} color="primary" variant="outlined" sx={{ width: "150px" }} startIcon={<ArrowUpward />}
                    >Publish</Button>
                </div>
            </div>

            <div>
                <div id={styles.firstChildDiv}>
                    <div className={styles.inputDiv}>
                        <label htmlFor="company_Id">Select Company</label>
                        <Autocomplete
                            fullWidth
                            size="medium"
                            disablePortal
                            id="company_Id"
                            name="company_Id"
                            options={companies}
                            sx={{ width: 300 }}
                            onChange={setCompanyId}
                            getOptionSelected={(option, value) => option.value === value.value}
                            renderInput={(params) => <TextField required {...params} label="Company" />}
                        />
                    </div>

                    <div className={styles.inputDiv}>
                        <label htmlFor="company_Id">Opening Date</label>
                        <input
                            value={ipoData.opening_date}
                            onChange={(ele) => handleDateChange('opening_date', ele.target.value)}
                            type="date"
                            className={styles.inputBox}
                        />
                    </div>

                    <div className={styles.inputDiv}>
                        <label htmlFor="company_Id">Closing Date</label>
                        <input
                            value={ipoData.closing_date}
                            onChange={(ele) => handleDateChange('closing_date', ele.target.value)}
                            type="date"
                            className={styles.inputBox}
                        />
                    </div>

                    <div className={styles.inputDiv}>
                        <label htmlFor="company_Id">Listing Date</label>
                        <input
                            value={ipoData.listing_date}
                            onChange={(ele) => handleDateChange('listing_date', ele.target.value)}
                            type="date"
                            className={styles.inputBox}
                        />
                    </div>
                    {/* </div> */}
                </div>


                <div className={styles.inputDiv}>
                    <label htmlFor="company_Id">Article Content</label>

                    <div style={{ minHeight: "500px", border: "1px solid gray" }}>
                        {/* <EditorComponent DEFAULT_INITIAL_DATA={{
                            "time": new Date().getTime(),
                            "blocks": ipoData.content
                        }} handleChange={handleChange}
                        /> */}

                        <div id="editorjs"></div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AddIpoArticle