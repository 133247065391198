import { Link, useNavigate } from "react-router-dom"
import styles from "../styles/Navbar.module.css";
import logo from "../img/Sovrenn_logo.png";
import { useDispatch } from "react-redux";
import { authAction } from "../Redux/Auth/action";

export const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const links = [
    {title: "Courses", to: "/courses"},
    {title: "Prime", to: "/prime"},
    {title: "IPOs", to: "/ipos"},
    {title: "Discovery", to: "/buckets"},
    {title: "Times", to: "/news"},
    {title: "Companies", to: "/companies"},
    {title: "Pulse", to: "/pulse"}
  ]

  const handleLogout = () => {
    localStorage.removeItem("sov_user");
    dispatch(authAction({
      isAuth: false,
      user: "",
      token: ""
    }))
    navigate("/login", true)
  };

  return (
    <div id={styles.NavbarMainContainer}>
      <Link to="/"><img src={logo} alt="logo" id={styles.sovrennLogo}/></Link>

        <div id={styles.NavbarRightDiv}>
          {links.map((ele, ind) =>{
            return <Link to={ele.to}>{ele.title}</Link>
          })}
          <button onClick={handleLogout}>Logout</button>
        </div>
    </div>
  )
}
