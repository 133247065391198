import { useParams } from "react-router-dom";
import commonStyles from "../../styles/Common.module.css";
import { useEffect, useRef, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { Add, ArrowUpward } from "@mui/icons-material";
import EditorComponent from "../Editor/EditorComponent";
import OverlayCircularProgress from "../Utils/OverlayCircularProgress";
import { initEditor } from "../../utils/initEditor";


const IpoArticleView = () => {
    const { slug } = useParams();
    const ejInstanceRef = useRef();
    const [ipoData, setIpoData] = useState({
        content: [],
        title: ""
    });
    const [newContent, setNewContent] = useState([]);
    const [progress, setProgress] = useState(false);

    useEffect(() => {
        if (slug) {
            getData();
        };

        return () => {

        };
    }, [slug]);

    useEffect(() => {

        if (ejInstanceRef.current === null && ipoData.content.length) {

            const DEFAULT_DATA = {
                "time": new Date().getTime(),
                "blocks": ipoData.content
            };

            initEditor({ DEFAULT_INITIAL_DATA: DEFAULT_DATA, handleChange, ejInstanceRef });
            console.log({ editorJs: "initialized Init Editor", time: Date.now() });
        };

        console.log({ editorJs: "Invoked EditorJs component", ejInstanceRef, time: Date.now() })

        return () => {
            ejInstanceRef?.current?.destroy();
            ejInstanceRef.current = null;
        };
    }, [ipoData]);

    const handleChange = (data) => {
        setNewContent(data.blocks);
    };

    const getData = async () => {
        try {
            const res = await fetch(`https://api.sovrenn.com/ipo/blocks/${slug}`, {
                headers: {
                    "authorization": "Bearer " + localStorage.getItem("sov_user")
                }
            });

            const data = await res.json();

            if(res.ok) {
                setIpoData({
                    title: data?.data?.title,
                    content: data?.data?.content
                });
            };
            
            return;
        }
        catch (err) {
            return {
                error: err.message
            }
        }
    };

    const handleUpdate = async () => {
        try {
            if (!newContent.length) {
                return alert("IPO Data is not updated");
            };

            setProgress(true);

            const res = await fetch(`https://api.sovrenn.com/ipo/update/${slug}`, {
                method: "PATCH",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                    "Content-type": "application/json"
                },
                body: JSON.stringify({
                    content: newContent
                })
            });

            const data = await res.json();

            setProgress(false);

            if (res.ok) {
                alert("Saved Article Successfully");
                return;
            };

            alert("Something went wrong");
            return;
        }
        catch (err) {

            console.log({ err });
            alert("Something went wrong");
            return;
        };
    };

    if (!ipoData.content.length) {
        return <div className={commonStyles.MainContainer}>

            <div className={commonStyles.headerDiv}>
                <h2>...loading</h2>
            </div>
        </div>
    };

    return (
        <div className={commonStyles.MainContainer}>
            <OverlayCircularProgress loading={progress} />

            <div className={commonStyles.headerDiv}>

                <h2>IPO Article - {ipoData?.title}</h2>

                <div>
                    <Button onClick={handleUpdate} disabled={newContent.length ? ipoData.content === newContent : true} color="secondary" variant="outlined" startIcon={<Add />} sx={{ width: "150px" }}
                    >Save</Button>
                </div>
            </div>
            {/* <EditorComponent DEFAULT_INITIAL_DATA={{
                "time": new Date().getTime(),
                "blocks": ipoData.content
            }} handleChange={handleChange}
            /> */}

            <div id="editorjs"></div>
        </div>
    )
}

export default IpoArticleView