
import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from "redux";
import thunk from "redux-thunk";
import { analyticsReducer } from "./Analytics/reducer";
import { authReducer } from "./Auth/reducer";
import { bucketReducer } from "./bucket/reducer";
import { companyReducer } from "./company/reducer";
import { courseReducer } from "./course/reducer";
import { primeReducer } from "./Prime/reducer";
import { trialReducer } from "./Trial/reducer";
import { userReducer } from "./Users/reducer";
import { macroReducer } from "./Macro/reducer";
import { nestedBucketReducer } from "./NestedBucket/reducer";
import { batchesReducer } from "./Batches/reducer";
import { ipoReducer } from "./IPO/reducer";
import { sectorsReducer } from "./sector/reducer";

const reducer = combineReducers({
    users: userReducer,
    auth: authReducer,
    course: courseReducer,
    bucket: bucketReducer,
    companies: companyReducer,
    prime: primeReducer,
    ipo: ipoReducer,
    analytics: analyticsReducer,
    trial: trialReducer,
    macro: macroReducer,
    nested_buckets: nestedBucketReducer,
    batches: batchesReducer,
    sectors: sectorsReducer,
});

// middleware
const middleWare = [thunk];

//creating store
export const store = createStore(reducer, compose(applyMiddleware(...middleWare)));