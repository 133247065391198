import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { dailySignupsByChannelsFun, dailySignupsFun, dailyUserActivityFun, getDailyWeeklyRevenueFun, getRevenueBySourceFun, getRevenueFun, getSubsConversionFun, lastTransactionsFun } from "../../Redux/Analytics/action";
import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/Revenue.module.css";
import tableStyles from "../Users/styles/Users.module.css";
import moment from "moment";
import { Bar, Line } from 'react-chartjs-2';
import { Chart } from "chart.js/auto";
import { Button, FormControl, InputLabel, MenuItem, Select, TablePagination } from "@mui/material";
import { Download } from "@mui/icons-material";
import { CSVLink } from "react-csv";

const colors = {
    Google: 'rgb(54,78,112)',
    YouTube: '#d6732d',
    WhatsApp: '#217867',
    Instagram: '#da2424',
    Twitter: 'rgb(54, 162, 235)',
    "Friends/Family": "black",
    Others: '#fee899',
    Linkedin: '#91b3fa',
    google_ad: 'rgb(54,78,112)',
    youtube_ad: '#d6732d',
    whatsapp_ad: '#217867',
    instagram_ad: '#da2424',
    twitter_ad: 'rgb(54, 162, 235)',
    linkedin_ad: '#91b3fa',
    login: '#d6732d',
    article_read: '#217867',
    page_visited: '#da2424',
    purchase: 'rgb(54, 162, 235)',
    tool_used: '#91b3fa',
}

const Revenue = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { revenue, last_transactions, trial_orders, is_loading, chart_data_daily, chart_data_weekly, total_documents, daily_weekly_revenue, conversions, monthly_revenue_by_source, weekly_revenue_by_source, daily_action_counts, daily_signups_by_channels, daily_signups_by_utm_sources, daily_user_activity_by_type } = useSelector((store) => store.analytics);
        
    const [ledgerData, setLedgerData] = useState({
        orderType: "",
        startDate: "",
        endDate: ""
    });

    const [dailyChartData, setDailyChartData] = useState({
        data: {
            labels: [],
            datasets: [
                {
                    label: 'Daily Trial Orders',
                    type: 'line',
                    data: [],
                    borderColor: 'rgb(54, 162, 235)',
                },
                {
                    label: 'Daily Signups',
                    type: 'line',
                    data: [],
                    borderColor: 'rgb(251,129,53)',
                },
                {
                    label: 'Daily Subscriptions',
                    type: 'line',
                    data: [],
                    borderColor: '#8ec038 ',
                }
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                enabled: true,
                intersect: true
            },
            interaction: {
                intersect: false
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: "Day of the Month"
                    }
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: "Total Numbers",
                    },
                    suggestedMin: 0,
                    suggestedMax: 150
                }
            }
        }
    });

    const [dailyRevenueChartData, setDailyRevenueChartData] = useState({
        data: {
            labels: [],
            datasets: [
                {
                    label: 'Daily Revenue',
                    type: 'line',
                    data: [],
                    borderColor: 'rgb(251,129,53)',
                }
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                enabled: true,
                intersect: true
            },
            interaction: {
                intersect: false
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: "Day of the Month"
                    }
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: "Total Amount",
                    },
                    suggestedMin: 0,
                    suggestedMax: 50000
                }
            }
        }
    });

    const [dailySignupsByChannelChartData, setDailySignupsByChannelChartData] = useState({
        data: {
            labels: [],
            datasets: [
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                enabled: true,
                intersect: true
            },
            interaction: {
                intersect: false
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: "Day of the Month"
                    }
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: "Total Signups",
                    },
                    suggestedMin: 0,
                    suggestedMax: 1000
                }
            }
        }
    });

    const [dailySignupsByUtmSourcesChartData, setDailySignupsByUtmSourcesChartData] = useState({
        data: {
            labels: [],
            datasets: [
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                enabled: true,
                intersect: true
            },
            interaction: {
                intersect: false
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: "Day of the Month"
                    }
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: "Total Signups",
                    },
                    suggestedMin: 0,
                    suggestedMax: 100
                }
            }
        }
    });

    const [dailyUserActiviyChartData, setDailyUserActiviyChartData] = useState({
        data: {
            labels: [],
            datasets: [
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                enabled: true,
                intersect: true
            },
            interaction: {
                intersect: false
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: "Day of the Month"
                    }
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: "Total Actions",
                    },
                    suggestedMin: 0,
                    suggestedMax: 25000
                }
            }
        }
    });

    const [dailyActionCountsChart, setDailyActionCountsChart] = useState({
        data: {
            labels: [],
            datasets: [
                {
                    label: 'Actions Count',
                    type: 'bar',
                    data: [],
                    borderColor: 'rgb(54,78,112)',
                    backgroundColor: 'rgb(54,78,112)',
                }
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                enabled: true,
                intersect: true
            },
            interaction: {
                intersect: false
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: "Day of the Month"
                    }
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: "Total Actions",
                    },
                    suggestedMin: 0,
                    suggestedMax: 50000
                }
            }
        }
    });

    const [weeklyRevenueChartData, setWeeklyRevenueChartData] = useState({
        data: {
            labels: [],
            datasets: [
                {
                    label: 'Weekly Revenue',
                    type: 'line',
                    data: [],
                    borderColor: '#fb8135',
                    backgroundColor: '#fb8135',
                },
                {
                    label: 'Admin Revenue',
                    type: 'line',
                    data: [],
                    borderColor: '#91b3fa',
                    backgroundColor: '#91b3fa',
                },
                {
                    label: 'App Revenue',
                    type: 'line',
                    data: [],
                    borderColor: '#111827',
                    backgroundColor: '#111827',
                },
                {
                    label: 'Website Revenue',
                    type: 'line',
                    data: [],
                    borderColor: '#217867',
                    backgroundColor: '#217867',
                },
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                enabled: true,
                intersect: true
            },
            interaction: {
                intersect: false
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: "Week (Start Date - End Date)"
                    }
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: "Total Amount",
                    },
                    suggestedMin: 0,
                    suggestedMax: 50000
                }
            }
        }
    });

    const [weeklyRevenueBySource, setWeeklyRevenueBySource] = useState({
        data: {
            labels: [],
            datasets: [
                {
                    label: 'Youtube',
                    type: 'line',
                    data: [],
                    borderColor: '#fb8135',
                    backgroundColor: '#fb8135',
                },
                {
                    label: 'Twitter',
                    type: 'line',
                    data: [],
                    borderColor: '#91b3fa',
                    backgroundColor: '#91b3fa',
                },
                {
                    label: 'Linkedin',
                    type: 'line',
                    data: [],
                    borderColor: '#111827',
                    backgroundColor: '#111827',
                },
                {
                    label: 'Friends/Family',
                    type: 'line',
                    data: [],
                    borderColor: '#217867',
                    backgroundColor: '#217867',
                },
                {
                    label: 'Others',
                    type: 'line',
                    data: [],
                    borderColor: '#77f5b4',
                    backgroundColor: '#77f5b4',
                },
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                enabled: true,
                intersect: true
            },
            interaction: {
                intersect: false
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: "Week (Start Date - End Date)"
                    }
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: "Total Amount",
                    },
                    suggestedMin: 0,
                    suggestedMax: 50000
                }
            }
        }
    });

    const [monthlyRevenueBySource, setMonthlyRevenueBySource] = useState({
        data: {
            labels: [],
            datasets: [
                {
                    label: 'Youtube',
                    type: 'line',
                    data: [],
                    borderColor: '#fb8135',
                    backgroundColor: '#fb8135',
                },
                {
                    label: 'Twitter',
                    type: 'line',
                    data: [],
                    borderColor: '#91b3fa',
                    backgroundColor: '#91b3fa',
                },
                {
                    label: 'Linkedin',
                    type: 'line',
                    data: [],
                    borderColor: '#111827',
                    backgroundColor: '#111827',
                },
                {
                    label: 'Friends/Family',
                    type: 'line',
                    data: [],
                    borderColor: '#217867',
                    backgroundColor: '#217867',
                },
                {
                    label: 'Others',
                    type: 'line',
                    data: [],
                    borderColor: '#77f5b4',
                    backgroundColor: '#77f5b4',
                },
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                enabled: true,
                intersect: true
            },
            interaction: {
                intersect: false
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: "Months"
                    }
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: "Total Amount",
                    },
                    suggestedMin: 0,
                    suggestedMax: 50000
                }
            }
        }
    });

    const [weeklyChartData, setWeeklyChartData] = useState({
        data: {
            lables: [],
            datasets: [
                {
                    label: 'Weekly Trial Orders',
                    type: 'line',
                    data: [],
                    borderColor: 'rgb(54, 162, 235)',
                },
                {
                    label: 'Weekly Signups',
                    type: 'line',
                    data: [],
                    borderColor: 'rgb(251,129,53)',
                },
                {
                    label: 'Weekly Subscriptions',
                    type: 'line',
                    data: [],
                    borderColor: '#8ec038 ',
                }
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                enabled: true,
                intersect: true
            },
            interaction: {
                intersect: false
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: "Week (Start Date - End Date)"
                    }
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: "Total Numbers",
                    },
                    suggestedMin: 0,
                    suggestedMax: 500
                }
            }
        }
    });

    const [revenueChartData, setRevenueChartData] = useState({
        data: {
            labels: ["Jan-23", "Feb-23", "Mar-23", "Apr-23", "May-23", "June-23", "July-23", "Aug-23", "Sep-23", "Oct-23", "Nov-23", "Dec-23", "Jan-24", "Feb-24", "Mar-24", "Apr-24", "May-24", "June-24", "July-24", "Aug-24", "Sep-24", "Oct-24"],
            datasets: [
                {
                    label: 'Monthly Revenue',
                    type: 'line',
                    data: [],
                    borderColor: '#217867',
                },
                {
                    label: 'Trial Orders',
                    type: 'line',
                    data: [0, 0],
                    borderColor: 'rgb(54, 162, 235)',
                },
                {
                    label: 'Full Access',
                    type: 'line',
                    data: [],
                    borderColor: 'rgb(54,78,112)',
                },
                {
                    label: 'Sovrenn Times',
                    type: 'line',
                    data: [],
                    borderColor: 'rgb(251,129,53)',
                },
                {
                    label: 'Sovrenn Discovery',
                    type: 'line',
                    data: [],
                    borderColor: '#8ec038 ',
                },
                {
                    label: 'Sovrenn Prime',
                    type: 'line',
                    data: [],
                    borderColor: '#da2424',
                },
                {
                    label: 'App Revenue',
                    type: 'line',
                    data: [0, 0, 0, 0],
                    borderColor: 'black',
                },
                {
                    label: 'Website Revenue',
                    type: 'line',
                    data: [0, 0, 0, 0],
                    borderColor: '#fee899',
                },
                {
                    label: 'Admin Revenue',
                    type: 'line',
                    data: [0, 0, 0, 0],
                    borderColor: '#91b3fa',
                },
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                enabled: true,
                intersect: true
            },
            interaction: {
                intersect: false
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: "Month"
                    }
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: "Total Amount",
                    },
                    suggestedMin: 0,
                    suggestedMax: 1000000
                }
            }
        }
    });

    const csvLinkRef = useRef();
    const [ledgerResult, setLedgerResult] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(400);

    const page_no = searchParams.get("page_no");
    const page_size = searchParams.get("page_size");

    useEffect(() => {
        dispatch(getRevenueFun());
        dispatch(dailySignupsFun());
        dispatch(getDailyWeeklyRevenueFun());
        dispatch(getSubsConversionFun());
        dispatch(getRevenueBySourceFun());
        dispatch(dailySignupsByChannelsFun());
        dispatch(dailyUserActivityFun());

        return () => {

        };
    }, []);

    useEffect(() => {

        dispatch(lastTransactionsFun(page_no, page_size))

        return () => {

        };
    }, [page_no, page_size]);


    useEffect(() => {
        if (ledgerResult.length) {
            csvLinkRef.current.link.click();
        }

        return () => {
        }
    }, [ledgerResult]);

    useEffect(() => {
        if (chart_data_weekly?.weeklySignups?.length) {
            const newChartData = {
                ...weeklyChartData,
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Weekly Trial Orders',
                            type: 'line',
                            data: [],
                            borderColor: 'rgb(54, 162, 235)',
                        },
                        {
                            label: 'Weekly Signups',
                            type: 'line',
                            data: [],
                            borderColor: 'rgb(251,129,53)',
                        },
                        {
                            label: 'Weekly Subscriptions',
                            type: 'line',
                            data: [],
                            borderColor: '#8ec038 ',
                        }
                    ]
                }
            };

            for (let i = 0; i < chart_data_weekly?.weeklySignups?.length; i++) {

                newChartData.data.datasets[0].data.push(chart_data_weekly.weeklyTrials[i]?.count || 0);

                newChartData.data.datasets[1].data.push(chart_data_weekly.weeklySignups[i]?.count || 0);

                newChartData.data.datasets[2].data.push(chart_data_weekly.weeklyRevenue[i]?.count || 0);

                newChartData.data.labels.push(moment(chart_data_weekly.weeklySignups[i]._id, "YYYY-MM-DD").format("Do MMM") + " to " + chart_data_weekly.weeklySignups[i].endDate);
            };

            setWeeklyChartData(newChartData);
        }

        if (chart_data_daily?.dailySignups?.length) {
            const newChartData = {
                ...dailyChartData,
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Daily Trial Orders',
                            type: 'line',
                            data: [],
                            borderColor: 'rgb(54, 162, 235)',
                        },
                        {
                            label: 'Daily Signups',
                            type: 'line',
                            data: [],
                            borderColor: 'rgb(251,129,53)',
                        },
                        {
                            label: 'Daily Subscriptions',
                            type: 'line',
                            data: [],
                            borderColor: '#8ec038 ',
                        }
                    ]
                }
            };

            for (let i = 0; i < chart_data_daily?.dailySignups?.length; i++) {

                newChartData.data.datasets[0].data.push(chart_data_daily.dailyTrials[i]?.count || 0);

                newChartData.data.datasets[1].data.push(chart_data_daily.dailySignups[i]?.count || 0);

                newChartData.data.datasets[2].data.push(chart_data_daily.dailySubs[i]?.count || 0);

                newChartData.data.labels.push(moment(chart_data_daily.dailySignups[i].date, "YYYY-MM-DD").format("Do MMM"));
            };

            setDailyChartData(newChartData);
        };


        if (revenue?.fullAccess?.grandTotal && revenue?.times?.grandTotal && revenue?.discovery?.grandTotal && revenue?.discovery?.grandTotal) {
            const newChartData = {
                ...revenueChartData,
                data: {
                    ...revenueChartData.data,
                    datasets: [
                        {
                            ...revenueChartData.data.datasets[0]
                        },
                        {
                            ...revenueChartData.data.datasets[1]
                        },
                        {
                            ...revenueChartData.data.datasets[2]
                        },
                        {
                            ...revenueChartData.data.datasets[3]
                        },
                        {
                            ...revenueChartData.data.datasets[4]
                        },
                        {
                            ...revenueChartData.data.datasets[5]
                        },
                        {
                            ...revenueChartData.data.datasets[6]
                        },
                        {
                            ...revenueChartData.data.datasets[7]
                        },
                        {
                            ...revenueChartData.data.datasets[8]
                        },
                    ]
                }
            };

            for (let i = 0; i < revenue.monthlyRevenue.length; i++) {
                newChartData.data.datasets[0].data.push(revenue.monthlyRevenue[i].total);
            }

            for (let i = 0; i < revenue.trial.totalByMonth.length; i++) {
                newChartData.data.datasets[1].data.push(revenue.trial.totalByMonth[i].total);
            }

            for (let i = 0; i < revenue.fullAccess.totalByMonth.length; i++) {
                newChartData.data.datasets[2].data.push(revenue.fullAccess.totalByMonth[i].total);
            }

            for (let i = 0; i < revenue.times.totalByMonth.length; i++) {
                newChartData.data.datasets[3].data.push(revenue.times.totalByMonth[i].total);
            }

            for (let i = 0; i < revenue.discovery.totalByMonth.length; i++) {
                newChartData.data.datasets[4].data.push(revenue.discovery.totalByMonth[i].total);
            }

            for (let i = 0; i < revenue.prime.totalByMonth.length; i++) {
                newChartData.data.datasets[5].data.push(revenue.prime.totalByMonth[i].total);
            }

            for (let i = 0; i < revenue.appRevenue.totalByMonth.length; i++) {
                newChartData.data.datasets[6].data.push(revenue.appRevenue.totalByMonth[i].total);
            }

            for (let i = 0; i < revenue.websiteRevenue.totalByMonth.length; i++) {
                newChartData.data.datasets[7].data.push(revenue.websiteRevenue.totalByMonth[i].total);
            }

            for (let i = 0; i < revenue.adminRevenue.totalByMonth.length; i++) {
                newChartData.data.datasets[8].data.push(revenue.adminRevenue.totalByMonth[i].total);
            }

            setRevenueChartData(newChartData);
        }

        if (daily_weekly_revenue?.dailyRevenue?.length) {
            const newChartData = {
                ...dailyRevenueChartData,
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Daily Revenue',
                            type: 'line',
                            data: [],
                            borderColor: 'rgb(251,129,53)',
                        },
                    ]
                }
            };

            for (let i = 0; i < daily_weekly_revenue?.dailyRevenue?.length; i++) {

                newChartData.data.datasets[0].data.push(daily_weekly_revenue?.dailyRevenue[i].total || 0);

                newChartData.data.labels.push(moment(daily_weekly_revenue?.dailyRevenue[i].date, "YYYY-MM-DD").format("Do MMM"));
            };

            if (daily_weekly_revenue?.dailyRevenueBySource?.length) {
                const updatedChartData = { ...newChartData };

                for (let i = 0; i < daily_weekly_revenue?.dailyRevenueBySource?.length; i++) {
                    const source = daily_weekly_revenue?.dailyRevenueBySource[i];


                    const dataSet = {
                        label: source.source,
                        type: 'line',
                        data: [],
                        borderColor: colors[source?.source] || "black",
                    };

                    for (let j = 0; j < source["total"].length; j++) {
                        dataSet.data.push(source["total"][j].total || 0);
                    }


                    updatedChartData.data.datasets.push(dataSet);
                }
            }

            setDailyRevenueChartData(newChartData);
        }

        if (daily_signups_by_channels?.length) {
            const newChartData = {
                ...dailySignupsByChannelChartData,
                data: {
                    labels: [],
                    datasets: [
                    ]
                }
            };

            if (daily_signups_by_channels?.length) {
                const updatedChartData = { ...newChartData };

                let maxLength = -Infinity;
                let maxTotalArr = [];

                for (let i = 0; i < daily_signups_by_channels?.length; i++) {

                    if (daily_signups_by_channels[i].total.length > maxLength) {
                        maxLength = daily_signups_by_channels[i].total.length;
                        maxTotalArr = daily_signups_by_channels[i].total;
                    };
                };

                for (let i = 0; i < maxTotalArr?.length; i++) {

                    newChartData.data.labels.push(moment(maxTotalArr[i].date, "YYYY-MM-DD").format("Do MMM"));
                };

                for (let i = 0; i < daily_signups_by_channels?.length; i++) {
                    const source = daily_signups_by_channels[i];

                    const dataSet = {
                        label: source.source,
                        type: 'line',
                        data: [],
                        borderColor: colors[source?.source] || "black",
                    };

                    for (let j = 0; j < source["total"].length; j++) {
                        dataSet.data.push(source["total"][j].total || 0);
                    }


                    updatedChartData.data.datasets.push(dataSet);
                }
            }

            setDailySignupsByChannelChartData(newChartData);
        }

        if (daily_user_activity_by_type?.length) {
            const newChartData = {
                ...dailyUserActiviyChartData,
                data: {
                    labels: [],
                    datasets: [
                    ]
                }
            };

            if (daily_user_activity_by_type?.length) {
                const updatedChartData = { ...newChartData };

                let maxLength = -Infinity;
                let maxTotalArr = [];

                for (let i = 0; i < daily_user_activity_by_type?.length; i++) {

                    if (daily_user_activity_by_type[i].total.length > maxLength) {
                        maxLength = daily_user_activity_by_type[i].total.length;
                        maxTotalArr = daily_user_activity_by_type[i].total;
                    };
                };

                for (let i = 0; i < maxTotalArr?.length; i++) {

                    newChartData.data.labels.push(moment(maxTotalArr[i].date, "YYYY-MM-DD").format("Do MMM"));
                };

                for (let i = 0; i < daily_user_activity_by_type?.length; i++) {
                    const source = daily_user_activity_by_type[i];

                    const dataSet = {
                        label: source.source,
                        type: 'line',
                        data: [],
                        borderColor: colors[source?.source] || "black",
                    };

                    for (let j = 0; j < source["total"].length; j++) {
                        dataSet.data.push(source["total"][j].total || 0);
                    }


                    updatedChartData.data.datasets.push(dataSet);
                }
            }

            setDailyUserActiviyChartData(newChartData);
        }

        if (daily_signups_by_utm_sources?.length) {
            const newChartData = {
                ...dailySignupsByUtmSourcesChartData,
                data: {
                    labels: [],
                    datasets: [
                    ]
                }
            };

            if (daily_signups_by_utm_sources?.length) {
                const updatedChartData = { ...newChartData };

                let maxLength = -Infinity;
                let maxTotalArr = [];

                for (let i = 0; i < daily_signups_by_utm_sources?.length; i++) {

                    if (daily_signups_by_utm_sources[i].total.length > maxLength) {
                        maxLength = daily_signups_by_utm_sources[i].total.length;
                        maxTotalArr = daily_signups_by_utm_sources[i].total;
                    };
                };

                for (let i = 0; i < maxTotalArr?.length; i++) {

                    newChartData.data.labels.push(moment(maxTotalArr[i].date, "YYYY-MM-DD").format("Do MMM"));
                };

                for (let i = 0; i < daily_signups_by_utm_sources?.length; i++) {
                    const source = daily_signups_by_utm_sources[i];

                    const dataSet = {
                        label: source.source,
                        type: 'line',
                        data: [],
                        borderColor: colors[source?.source] || "black",
                    };

                    for (let j = 0; j < source["total"].length; j++) {
                        dataSet.data.push(source["total"][j].total || 0);
                    }


                    updatedChartData.data.datasets.push(dataSet);
                }
            }

            setDailySignupsByUtmSourcesChartData(newChartData);
        }

        if (daily_action_counts?.length) {
            const newChartData = {
                ...dailyActionCountsChart,
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Action Counts',
                            type: 'bar',
                            data: [],
                            borderColor: 'rgb(54,78,112)',
                            backgroundColor: 'rgb(54,78,112)',
                        },
                    ]
                }
            };

            for (let i = 0; i < daily_action_counts?.length; i++) {

                newChartData.data.datasets[0].data.push(daily_action_counts[i].total || 0);

                newChartData.data.labels.push(moment(daily_action_counts[i].date, "YYYY-MM-DD").format("Do MMM"));
            };

            setDailyActionCountsChart(newChartData);
        };

        if (daily_weekly_revenue?.weeklyRevenue?.length) {
            const newChartData = {
                ...weeklyRevenueChartData,
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Weekly Revenue',
                            type: 'line',
                            data: [],
                            borderColor: '#fb8135',
                            backgroundColor: '#fb8135',
                        },
                        {
                            label: 'Admin Revenue',
                            type: 'line',
                            data: [],
                            borderColor: '#91b3fa',
                            backgroundColor: '#91b3fa',
                        },
                        {
                            label: 'App Revenue',
                            type: 'line',
                            data: [],
                            borderColor: '#111827',
                            backgroundColor: '#111827',
                        },
                        {
                            label: 'Website Revenue',
                            type: 'line',
                            data: [],
                            borderColor: '#217867',
                            backgroundColor: '#217867',
                        },
                    ]
                }
            };

            for (let i = 0; i < daily_weekly_revenue?.weeklyRevenue?.length; i++) {

                newChartData.data.datasets[0].data.push(daily_weekly_revenue?.weeklyRevenue[i]?.total || 0);

                newChartData.data.datasets[1].data.push(daily_weekly_revenue?.weeklyRevenueBySource.admin[i]?.total || 0);

                newChartData.data.datasets[2].data.push(daily_weekly_revenue?.weeklyRevenueBySource.app[i]?.total || 0);

                newChartData.data.datasets[3].data.push(daily_weekly_revenue?.weeklyRevenueBySource.website[i]?.total || 0);

                newChartData.data.labels.push(moment(daily_weekly_revenue?.weeklyRevenue[i].startDate, "YYYY-MM-DD").format("Do") + " to " + moment(daily_weekly_revenue?.weeklyRevenue[i].endDate, "YYYY-MM-DD").format("Do MMM"));
            };

            setWeeklyRevenueChartData(newChartData);
        }

        if (weekly_revenue_by_source?.Twitter?.length) {

            const newChartData = {
                ...weeklyRevenueBySource,
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Youtube',
                            type: 'line',
                            data: [],
                            borderColor: '#fb8135',
                            backgroundColor: '#fb8135',
                        },
                        {
                            label: 'Twitter',
                            type: 'line',
                            data: [],
                            borderColor: '#91b3fa',
                            backgroundColor: '#91b3fa',
                        },
                        {
                            label: 'Linkedin',
                            type: 'line',
                            data: [],
                            borderColor: '#111827',
                            backgroundColor: '#111827',
                        },
                        {
                            label: 'Friends/Family',
                            type: 'line',
                            data: [],
                            borderColor: '#217867',
                            backgroundColor: '#217867',
                        },
                        {
                            label: 'Others',
                            type: 'line',
                            data: [],
                            borderColor: '#77f5b4',
                            backgroundColor: '#77f5b4',
                        },
                    ]
                }
            };

            for (let i = 0; i < weekly_revenue_by_source?.Twitter?.length; i++) {

                newChartData.data.datasets[0].data.push(weekly_revenue_by_source?.YouTube[i]?.total || 0);

                newChartData.data.datasets[1].data.push(weekly_revenue_by_source?.Twitter[i]?.total || 0);

                newChartData.data.datasets[2].data.push(weekly_revenue_by_source?.Linkedin[i]?.total || 0);

                newChartData.data.datasets[3].data.push(weekly_revenue_by_source["Friends/Family"][i]?.total || 0);

                newChartData.data.datasets[4].data.push(weekly_revenue_by_source?.Others[i]?.total || 0);

                newChartData.data.labels.push(moment(weekly_revenue_by_source?.Twitter[i].startDate, "YYYY-MM-DD").format("Do") + " to " + moment(weekly_revenue_by_source?.Twitter[i].endDate, "YYYY-MM-DD").format("Do MMM"));
            };

            setWeeklyRevenueBySource(newChartData);
        }

        if (monthly_revenue_by_source?.Twitter?.length) {

            const newChartData = {
                ...monthlyRevenueBySource,
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Youtube',
                            type: 'line',
                            data: [],
                            borderColor: '#fb8135',
                            backgroundColor: '#fb8135',
                        },
                        {
                            label: 'Twitter',
                            type: 'line',
                            data: [],
                            borderColor: '#91b3fa',
                            backgroundColor: '#91b3fa',
                        },
                        {
                            label: 'Linkedin',
                            type: 'line',
                            data: [],
                            borderColor: '#111827',
                            backgroundColor: '#111827',
                        },
                        {
                            label: 'Friends/Family',
                            type: 'line',
                            data: [],
                            borderColor: '#217867',
                            backgroundColor: '#217867',
                        },
                        {
                            label: 'Others',
                            type: 'line',
                            data: [],
                            borderColor: '#77f5b4',
                            backgroundColor: '#77f5b4',
                        },
                    ]
                }
            };

            for (let i = 0; i < monthly_revenue_by_source?.Twitter?.length; i++) {

                newChartData.data.datasets[0].data.push(monthly_revenue_by_source?.YouTube[i]?.total || 0);

                newChartData.data.datasets[1].data.push(monthly_revenue_by_source?.Twitter[i]?.total || 0);

                newChartData.data.datasets[2].data.push(monthly_revenue_by_source?.Linkedin[i]?.total || 0);

                newChartData.data.datasets[3].data.push(monthly_revenue_by_source["Friends/Family"][i]?.total || 0);

                newChartData.data.datasets[4].data.push(monthly_revenue_by_source?.Others[i]?.total || 0);

                newChartData.data.labels.push(moment((monthly_revenue_by_source?.Twitter[i].year + "-" + monthly_revenue_by_source?.Twitter[i].month), "YYYY-MM").format("MMM YYYY"));
            };

            setMonthlyRevenueBySource(newChartData);
        }

        return () => {
            setRevenueChartData({
                data: {
                    labels: ["Jan-23", "Feb-23", "Mar-23", "Apr-23", "May-23", "June-23", "July-23", "Aug-23", "Sep-23", "Oct-23", "Nov-23", "Dec-23", "Jan-24", "Feb-24", "Mar-24", "Apr-24", "May-24", "June-24", "July-24", "Aug-24", "Sep-24", "Oct-24"],
                    datasets: [
                        {
                            label: 'Monthly Revenue',
                            type: 'line',
                            data: [],
                            borderColor: '#217867',
                        },
                        {
                            label: 'Trial Orders',
                            type: 'line',
                            data: [0, 0],
                            borderColor: 'rgb(54, 162, 235)',
                        },
                        {
                            label: 'Full Access',
                            type: 'line',
                            data: [],
                            borderColor: 'rgb(251,129,53)',
                        },
                        {
                            label: 'Sovrenn Times',
                            type: 'line',
                            data: [],
                            borderColor: 'rgb(54,78,112)',
                        },
                        {
                            label: 'Sovrenn Discovery',
                            type: 'line',
                            data: [],
                            borderColor: '#8ec038 ',
                        },
                        {
                            label: 'Sovrenn Prime',
                            type: 'line',
                            data: [],
                            borderColor: '#da2424',
                        },
                        {
                            label: 'App Orders',
                            type: 'line',
                            data: [0, 0, 0, 0],
                            borderColor: 'black',
                        },
                        {
                            label: 'Website Revenue',
                            type: 'line',
                            data: [0, 0, 0, 0],
                            borderColor: '#fee899',
                        },
                        {
                            label: 'Admin Revenue',
                            type: 'line',
                            data: [0, 0, 0, 0],
                            borderColor: '#91b3fa',
                        },
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    tooltips: {
                        enabled: true,
                        intersect: true
                    },
                    interaction: {
                        intersect: false
                    },
                    scales: {
                        x: {
                            display: true,
                            title: {
                                display: true,
                                text: "Month"
                            }
                        },
                        y: {
                            display: true,
                            title: {
                                display: true,
                                text: "Total Amount",
                            },
                            suggestedMin: 0,
                            suggestedMax: 1000000
                        }
                    }
                }
            });
        };
    }, [chart_data_daily, chart_data_weekly, revenue, daily_weekly_revenue, weekly_revenue_by_source, monthly_revenue_by_source, daily_action_counts, daily_signups_by_channels, daily_user_activity_by_type]);

    const handleChangeLedger = (ele) => {
        const { name, value } = ele.target;

        if (name === "month") {
            const date = value.split(":");

            setLedgerData({
                ...ledgerData,
                startDate: date[0],
                endDate: date[1]
            });

            return;
        };

        setLedgerData({
            ...ledgerData,
            [name]: value
        });

    };

    const handleDownload = async () => {

        if (!ledgerData.startDate || !ledgerData.endDate || !ledgerData.orderType) {
            alert("Select all the required fields.");
            return;
        }

        const res = await fetch(`https://api.sovrenn.com/analytics/ledger`, {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                "Content-type": "application/json"
            },
            body: JSON.stringify(ledgerData)
        });

        const data = await res.json();

        if (res.ok) {
            setLedgerResult(data.data);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        changePath(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        changePath(0, event.target.value);
    };

    const changePath = (page_no, page_size) => {
        navigate(`/revenue?page_no=${page_no}&page_size=${page_size}`);
    };

    const csvHeadersLedger = [
        { label: "Invoice Number", key: "invoice_number" },
        { label: "Transaction Id", key: "transaction_id" },
        { label: "Order Type", key: "type" },
        { label: "Customer Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Phone Number", key: "phone_number" },
        { label: "State", key: "state" },
        { label: "Amount", key: "amount" },
        { label: "Order Date / Course Date", key: "order_date" },
    ];

    return (
        <div className={commonStyles.MainContainer}>

            <div className={commonStyles.headerDiv}>
                <h1>Revenue</h1>

                <CSVLink
                    ref={csvLinkRef}
                    headers={csvHeadersLedger}
                    data={ledgerResult}
                    filename={`${ledgerData.orderType}-${ledgerData.startDate}.csv`}
                    className="hidden"
                    target="_blank"
                    style={{ display: "none" }}
                >Download Result</CSVLink>
            </div>

            <div className={styles.revenueAnalytics}>

                <div className={styles.revenueCards}>
                    <h3>Education</h3>
                    <h1>₹{revenue.education?.toLocaleString("en-IN")}</h1>

                    <Link to="/courses" className={styles.revenueCardsBtn}>Analyse</Link>
                </div>

                <div className={styles.revenueCards}>
                    <h3>Total Pulse Portfolios</h3>
                    <h1>{conversions.totalPulsePortfolios?.toLocaleString("en-IN")}</h1>
                </div>


                <div className={styles.revenueCards}>
                    <h3>Trial Access</h3>
                    <h1>₹{revenue?.trial?.grandTotal?.toLocaleString("en-IN")}</h1>

                </div>

                <div className={styles.revenueCards}>
                    <h3>Full Access</h3>
                    <h1>₹{revenue?.fullAccess?.grandTotal?.toLocaleString("en-IN")}</h1>

                </div>

                <div className={styles.revenueCards}>
                    <h3>Sovrenn Times</h3>
                    <h1>₹{revenue?.times?.grandTotal?.toLocaleString("en-IN")}</h1>

                </div>

                <div className={styles.revenueCards}>
                    <h3>Sovrenn Discovery</h3>
                    <h1>₹{revenue?.discovery?.grandTotal?.toLocaleString("en-IN")}</h1>
                </div>

                <div className={styles.revenueCards}>
                    <h3>Overall Revenue</h3>
                    <h1>₹{revenue?.overallRevenue?.toLocaleString("en-IN")}</h1>
                </div>

                <div className={styles.revenueCards}>
                    <h3>Trial Conversion</h3>
                    <h1>{conversions.trialConversion}%</h1>
                </div>

                <div className={styles.revenueCards}>
                    <h3>Full Access Conversion</h3>
                    <h1>{conversions.fullAccessConversion}%</h1>
                </div>

                <div className={styles.revenueCards}>
                    <h3>Website Conversion <span style={{ fontSize: "small" }}>(Last 15 Days)</span></h3>
                    <h1>{conversions.website}%</h1>
                </div>

                <div className={styles.revenueCards}>
                    <h3>Mobile App Conversion <span style={{ fontSize: "small" }}>(Last 15 Days)</span></h3>
                    <h1>{conversions.app}%</h1>
                </div>


                <div className={styles.revenueCards}>
                    <h3>Highest Day so far</h3>
                    <h4>({moment(revenue?.mostEarnedDaySoFar?._id).format("Do MMM YY")})</h4>
                    <h1>₹{revenue?.mostEarnedDaySoFar?.totalAmount?.toLocaleString("en-IN")}</h1>
                </div>


                <div className={styles.revenueCards}>
                    <h3>Highest Week so far</h3>
                    <h4>({moment(revenue?.mostEarnedWeekSoFar?.startDate).format("Do MMM YY") + " - " + moment(revenue?.mostEarnedWeekSoFar?.endDate).format("Do MMM YY")})</h4>
                    <h1>₹{revenue?.mostEarnedWeekSoFar?.totalAmount?.toLocaleString("en-IN")}</h1>
                </div>


            </div>

            <div className={styles.ledgerDiv}>
                <h2>Download User Orders List</h2>

                <div className={styles.csvRevenue}>
                    <FormControl style={{ width: "300px" }}>
                        <InputLabel id="demo-simple-select-label">Order Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Order Type"
                            onChange={handleChangeLedger}
                            name="orderType"
                        >
                            <MenuItem value={"all"}>All</MenuItem>
                            <MenuItem value={"full-access"}>Full Access</MenuItem>
                            <MenuItem value={"news"}>News</MenuItem>
                            <MenuItem value={"basket"}>Discovery</MenuItem>
                            <MenuItem value={"trial"}>Trial</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl style={{ width: "300px" }}>
                        <InputLabel id="demo-simple-select-label">Month</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Month"
                            onChange={handleChangeLedger}
                            name="month"
                        >
                            <MenuItem value={"2023-01-01:2023-12-31"}>All Months</MenuItem>
                            <MenuItem value={"2023-01-01:2023-01-31"}>January 23</MenuItem>
                            <MenuItem value={"2023-02-01:2023-02-28"}>February 23</MenuItem>
                            <MenuItem value={"2023-03-01:2023-03-31"}>March 23</MenuItem>
                            <MenuItem value={"2023-04-01:2023-04-30"}>April 23</MenuItem>
                            <MenuItem value={"2023-05-01:2023-05-31"}>May 23</MenuItem>
                            <MenuItem value={"2023-06-01:2023-06-30"}>June 23</MenuItem>
                            <MenuItem value={"2023-07-01:2023-07-31"}>July 23</MenuItem>
                            <MenuItem value={"2023-08-01:2023-08-31"}>August 23</MenuItem>
                            <MenuItem value={"2023-09-01:2023-09-30"}>September 23</MenuItem>
                            <MenuItem value={"2023-10-01:2023-10-31"}>October 23</MenuItem>
                            <MenuItem value={"2023-11-01:2023-11-30"}>November 23</MenuItem>
                            <MenuItem value={"2023-12-01:2023-12-31"}>December 23</MenuItem>
                            <MenuItem value={"2024-01-01:2024-01-31"}>January 24</MenuItem>
                            <MenuItem value={"2024-02-01:2024-02-29"}>February 24</MenuItem>
                            <MenuItem value={"2024-03-01:2024-03-31"}>March 24</MenuItem>
                            <MenuItem value={"2024-04-01:2024-04-30"}>April 24</MenuItem>
                            <MenuItem value={"2024-05-01:2024-05-31"}>May 24</MenuItem>
                            <MenuItem value={"2024-06-01:2024-06-30"}>June 24</MenuItem>
                            <MenuItem value={"2024-07-01:2024-07-31"}>July 24</MenuItem>
                            <MenuItem value={"2024-08-01:2024-08-31"}>August 24</MenuItem>
                            <MenuItem value={"2024-09-01:2024-09-30"}>September 24</MenuItem>
                            <MenuItem value={"2024-10-01:2024-10-31"}>October 24</MenuItem>
                        </Select>
                    </FormControl>

                    <Button style={{ width: "200px" }} variant="outlined" startIcon={<Download />} onClick={handleDownload}>
                        Download
                    </Button>
                </div>
            </div>

            <div className={styles.trialChartDiv}>
                <h2>Revenue Chart</h2>
                <div>
                    <Line data={revenueChartData.data} options={revenueChartData.options} />
                </div>
            </div>

            <div className={styles.trialChartDiv}>
                <h2>Daily Revenue</h2>
                <div>
                    <Line data={dailyRevenueChartData.data} options={dailyRevenueChartData.options} />
                </div>
            </div>


            <div className={styles.trialChartDiv}>
                <h2>Weekly Revenue</h2>
                <div>
                    <Bar data={weeklyRevenueChartData.data} options={weeklyRevenueChartData.options} />
                </div>
            </div>

            <div className={styles.trialChartDiv}>
                <h2>Daily Orders & Signups Chart</h2>
                <div>
                    <Line data={dailyChartData.data} options={dailyChartData.options} />
                </div>
            </div>

            <div className={styles.trialChartDiv}>
                <h2>Daily Signups By the Channels</h2>
                <div>
                    <Line data={dailySignupsByChannelChartData.data} options={dailySignupsByChannelChartData.options} />
                </div>
            </div>

            <div className={styles.trialChartDiv}>
                <h2>Daily Signups By the Ad Sources</h2>
                <div>
                    <Line data={dailySignupsByUtmSourcesChartData.data} options={dailySignupsByUtmSourcesChartData.options} />
                </div>
            </div>

            <div className={styles.trialChartDiv}>
                <h2>Daily Action Counts by the Activity</h2>
                <div>
                    <Line data={dailyUserActiviyChartData.data} options={dailyUserActiviyChartData.options} />
                </div>
            </div>

            <div className={styles.trialChartDiv}>
                <h2>Weekly Orders & Signups Chart</h2>
                <div>
                    <Line data={weeklyChartData.data} options={weeklyChartData.options} />
                </div>
            </div>

            <div className={styles.trialChartDiv}>
                <h2>Weekly Revenue by Where did hear about Sovrenn</h2>
                <div>
                    <Line data={weeklyRevenueBySource.data} options={weeklyRevenueBySource.options} />
                </div>
            </div>

            <div className={styles.trialChartDiv}>
                <h2>Monthly Revenue by Where did hear about Sovrenn</h2>
                <div>
                    <Line data={monthlyRevenueBySource.data} options={monthlyRevenueBySource.options} />
                </div>
            </div>

            <div className={styles.trialChartDiv}>
                <h2>Daily Action Counts</h2>
                <div>
                    <Bar data={dailyActionCountsChart.data} options={dailyActionCountsChart.options} />
                </div>
            </div>


            <div className={styles.latestOrderDiv}>
                <h2>Latest Orders</h2>

                <table id={tableStyles.userListContainer}>
                    <thead id={tableStyles.userListHead}>
                        <tr className={tableStyles.userCard}>
                            <th>Sr. No</th>
                            <th>User Name</th>
                            <th>Email</th>
                            <th>Phone No.</th>
                            <th>Product / Company</th>
                            <th>Amount Paid</th>
                            <th>Bought From</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        {last_transactions?.map((ele, ind) => {
                            return <tr className={tableStyles.userCard} key={ind}>
                                <td>{(page_no * page_size) + (ind + 1)}</td>
                                <td><Link to={`/users/${ele._id}`}>
                                    {ele.first_name} {ele.last_name}
                                </Link></td>
                                <td>{ele.email}</td>
                                <td>{ele.country_code} {ele.phone_number}</td>
                                <td>{ele.product || ele.company}</td>
                                <td>₹{ele.amount}</td>
                                <td>{ele.bought_from}</td>
                                <td>{moment(ele.createdAt).format("Do MMM YY")}</td>
                            </tr>
                        })}
                    </tbody>
                </table>

                <TablePagination
                    component="div"
                    count={total_documents}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[100, 400, 600]}
                />
            </div>
        </div>
    )
}

export default Revenue